import { Box, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ScrollTop from "./ScrollToTop";
import React from "react";
import Footer from "../main/footer";
import ContactForm from "../main/contactForm";
import NavBar from "../main/nav-bar";
import { gdprText } from "../../texts/gdprText";


export default function gdprPage() {
    const location = useLocation();
    const targetId = location.state?.targetId;

    useEffect(() => {
        const el = document.getElementById(targetId);
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        }

    }, [targetId]);
    return (
        <Box sx={{ width: '100%', position: 'realtive', backgroundColor: 'primary.light' }}>
            <Stack
                display='flex'
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <NavBar />
                <Container sx={{ py: 5, backgroundColor: "background.paper", boxShadow:10 }}>
                    <Stack spacing={4}>
                        {gdprText.map((section, index) => (
                            <React.Fragment key={index}>
                                <Typography variant={index === 0 ? "h4" : "h5"} fontWeight="bold" color="primary.dark">
                                    {section.number}. {section.title}
                                </Typography>
                                {section.content.map((text, subIndex) => (
                                    <Typography  sx={{ pl: 3, mt: '0 !important', pt:3, whiteSpace: "pre-wrap",}}  key={subIndex}>
                                        {text}
                                    </Typography>
                                ))}
                            </React.Fragment>
                        ))}
                    </Stack>
                </Container>
                <ContactForm />
                <Footer />
                <ScrollTop />
            </Stack>
        </Box>
    );
}