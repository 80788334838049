import { Box, Grid, Stack, styled } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { WindowNetsText } from "../../texts/products/WindowNetsText";
import ContactForm from "../main/contactForm";
import Footer from "../main/footer";
import NavBar from "../main/nav-bar";
import { NetsLanding } from "../main/netsLanding";
import { ProductDescription } from "../models/productDescription";
import { ProductList } from "../models/productList";
import ScrollTop from "../models/ScrollToTop";






export const WindowNets = () => {
    return (
        <Box sx={{ width: '100%', position: 'realtive', backgroundColor: 'secondary.light' }}>
          <Stack
            display='flex'
            direction="column"
            justifyContent="center"
            alignItems="center"
    
          >
            <NavBar />
            <NetsLanding title={WindowNetsText.title} subtitle={WindowNetsText.subtitle} landingBox={WindowNetsText.landingBox} image={WindowNetsText.image} />
            {WindowNetsText.nets.map((prd) => (  
            <React.Fragment>
              <Grid
                    container
                    width={"100%"}
                    display={"flex"}
                    maxWidth={"1920px"}
                    rowSpacing={1}
                    rowGap={{ xs: 1, sm: 2, md: 3 }}
                    columns={{ xs: 9, sm: 9, lg:18, xl: 18 }}
                    sx={{
                       m:0, p:0
                    }}>
                        <Grid xs={9}>
                        <ProductDescription folder="nets" title={prd.name} description={prd.description} images={prd.images}/>
                        </Grid>
                        <Grid xs={9}>
                        <ProductList  items={prd.adventages}/>
                        </Grid>
                    
                </Grid>
              </React.Fragment>
                              ))}
            <ContactForm />
            <Footer />
            <ScrollTop />
          </Stack>
        </Box>
      );
}