// Router.tsx
import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { BlindsMoreSpecs } from './components/BlindsMore';
import blindsPage from './components/blindsPage';
import ScrollTop from './components/main/scrollNav';
import { BlindsDetails, EmergencyDetails, ForgingCmp, MoreServicesDetails, RegularService, Sealing, StuckWindows, WindowsDetails, WindowsillDetails, Winter_Summer } from './components/main/serviceDetails';
import mainPage from './components/mainPage';
import { DoorNets } from './components/nets/DoorNets';
import { WindowNets } from './components/nets/WindowNets';
import netsPage from './components/netsPage';
import { WindowDetailsSpecs } from './components/windows/windowDetail';
import { Helmet } from 'react-helmet';
import windowsPage from './components/windowsPage';
import gdprPage from './components/models/GdprPage';
interface RouteConfig {
  path: string;
  component: React.ComponentType<any>;
  title?: string;
  description?: string;
}


const CanonicalWrapper: React.FC<{ children: React.ReactNode; title: string, description: string }> = ({ children, title, description }) => {
  const location = useLocation();
  const canonicalUrl = `https://alvip-okno.sk`+location.pathname;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </>
  );
};




export const baseurl = '';


const routes: RouteConfig[] = [
  {
    path: baseurl + '/',
    component: mainPage,
    title: "Servis a oprava okien Košice - Nastavenie, výmena tesnení, montáž žalúzií a sietí proti hmyzu",
    description: "Servis plastových okien v Košiciach - opravy kovania, výmena tesnení, nastavenie zimného a letného režimu. Opravy žalúzií, montáž sieťok proti hmyzu a ďalšie služby. Profesionálny servis okien s dlhoročnými skúsenosťami. Kontaktujte nás ešte dnes!"

  },

  {
    path: baseurl + '/tieniaca-technika',
    component: blindsPage,
    title: "Tieniaca technika Košice - Žalúzie, rolety, opravy a montáž",
    description: "Predaj, montáž a opravy tieniacej techniky v Košiciach - žalúzie, rolety, markízy, siete proti hmyzu. Nastavenie letného a zimného režimu, výmena kovania, pravidelná údržba a opravy. Kvalitné riešenia na mieru pre domácnosti aj firmy. Kontaktujte nás!"

  },
  {
    path: baseurl + '/plastove-okna',
    component: windowsPage,
    title: "Plastové okná Košice - Predaj, montáž, servis a opravy okien",
    description: "Plastové okná na mieru v Košiciach - profesionálna montáž, opravy kovania, výmena tesnení, nastavenie letného a zimného režimu. Kvalitné a úsporné riešenia pre váš domov či kanceláriu. Kontaktujte nás pre bezplatnú konzultáciu a cenovú ponuku!"

  },
  {
    path: baseurl + '/oprava-vymena-kovania',
    component: ForgingCmp,
    title: "Oprava a výmena kovania okien Košice - Rýchly a spoľahlivý servis",
    description: "Oprava a výmena kovania plastových a drevených okien v Košiciach. Nastavenie mechanizmov, mazanie, výmena poškodených častí a celková údržba. Zabezpečte si hladké a bezpečné fungovanie vašich okien. Kontaktujte nás ešte dnes!"

  },
  {
    path: baseurl + '/zaseknute-okna-dvere',
    component: StuckWindows,
    title: "Oprava zaseknutých okien a dverí Košice - Rýchly výjazd a servis",
    description: "Máte problém so zaseknutými oknami alebo dverami? Poskytujeme expresný servis v Košiciach a okolí. Opravy mechanizmov, výmena kovania a tesnení, nastavenie pre ľahké otváranie. Zavolajte a vyriešime váš problém ešte dnes!"

  },
  {
    path: baseurl + '/zimny-letny-rezim',
    component: Winter_Summer,
    title: "Nastavenie zimného a letného režimu okien Košice - Úspora energie",
    description: "Správne nastavenie okien na zimný a letný režim zlepší izoláciu a predĺži ich životnosť. Profesionálny servis v Košiciach a okolí - rýchla kontrola, opravy a údržba. Objednajte si odborné nastavenie okien už dnes!"
  },
  {
    path: baseurl + '/pravidelna-udrzba',
    component: RegularService,
    title: "Pravidelná údržba okien a dverí Košice - Predĺžte ich životnosť",
    description: "Zabezpečte dlhú životnosť vašich okien a dverí pravidelnou údržbou. Kontrola, mazanie, výmena kovania a tesnení v Košiciach a okolí. Profesionálny servis pre domácnosti aj firmy. Kontaktujte nás pre dlhodobú starostlivosť o vaše okná."

  },
  {
    path: baseurl + '/vymena-tesneni',
    component: Sealing,
    title: "Výmena tesnení okien a dverí Košice - Lepšia izolácia a úspora",
    description: "Staré tesnenia prepúšťajú chlad a hluk? Ponúkame odbornú výmenu tesnení na plastových a drevených oknách v Košiciach. Zvýšte tepelnú izoláciu a komfort vo vašej domácnosti. Kontaktujte nás pre bezplatnú konzultáciu."

  },

  {
    path: baseurl + '/havarijny-servis',
    component: EmergencyDetails,
    title: "Havarijný servis okien Košice - Rýchla oprava pri poškodení",
    description: "Rozbité alebo poškodené okno? Ponúkame havarijný servis a opravy okien v Košiciach. Expresná výmena kovania, skla a tesnení. Profesionálny výjazd už do 24 hodín. Kontaktujte nás pre rýchlu pomoc!"
  },
  {
    path: baseurl + '/siete-proti-hmyzu',
    component: netsPage,
    title: "Siete proti hmyzu Košice - Montáž na mieru pre vaše okná a dvere",
    description: "Chráňte svoj domov pred hmyzom! Ponúkame kvalitné siete proti hmyzu pre okná a dvere v Košiciach. Pevné, posuvné a rolovacie riešenia na mieru. Kontaktujte nás pre cenovú ponuku a montáž!"
  },

  {
    path: baseurl + "/plastove-okna/:productId",
    component: WindowDetailsSpecs,
    title: "Plastové okná Košice - Predaj, montáž, servis a opravy okien",
    description: "Ponúkame na predaj široký sortiment plastových okien na mieru v Košiciach. Máme na predaj plastové okná Ideal 4000, Ideal 5000, Ideal 7000, ale aj Ideal 8000. Profesionálna montáž, servis a opravy okien. Kontaktujte nás pre cenovú ponuku a my sa o všetko postaráme."
  },
  {
    path: baseurl + "/tieniaca-technika/:productId",
    component: BlindsMoreSpecs,
    title: "Tieniaca technika Košice - Žalúzie, rolety, vonkajšie, či vnútorné žalúzie",
    description: "Ponúkame na predaj interiérové žalúzie, vertikálne žalúzie, rolety deň a noc, strešné rolety, hliniakové a plastové vonkajšie rolety, ale aj vonkajšie žalúzie. Ponúkame kompletný servis a montáž na mieru, odborné poradenstvo a kvalitné riešenia pre vašu domácnosť alebo firmu. Kontaktujte nás pre cenovú ponuku a my sa o všetko postaráme."
  },

  {
    path: baseurl + "/siete-proti-hmyzu/okenne-siete",
    component: WindowNets,
    title: "Okenné siete proti hmyzu Košice - Kvalitná ochrana na mieru",
    description: "Hľadáte účinnú ochranu proti hmyzu? Ponúkame pevné, posuvné a rolovacie okenné siete na mieru pre plastové aj drevené okná v Košiciach. Profesionálna montáž a dlhá životnosť. Kontaktujte nás pre cenovú ponuku!"
  },
  {
    path: baseurl + "/siete-proti-hmyzu/dverove-siete",
    component: DoorNets,
    title: "Okenné siete proti hmyzu Košice - Kvalitná ochrana na mieru",
    description: "Hľadáte účinnú ochranu proti hmyzu? Ponúkame pevné, posuvné a rolovacie okenné siete na mieru pre plastové aj drevené okná v Košiciach. Profesionálna montáž a dlhá životnosť. Kontaktujte nás pre cenovú ponuku!"
  },
  {
    path: baseurl + "/ochrana-osobnych-udajov",
    component: gdprPage
  },
  {
    path: '*',
    component: mainPage,
  }
];

const AppRouter = () => {


  return (
    <BrowserRouter>
      <ScrollTop />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={
            <CanonicalWrapper title={
              route.title || "Servis a oprava okien Košice - Nastavenie, výmena tesnení, montáž žalúzií a sietí proti hmyzu"} 
              description={route.description || "Servis plastových okien v Košiciach - opravy kovania, výmena tesnení, nastavenie zimného a letného režimu. Opravy žalúzií, montáž sieťok proti hmyzu a ďalšie služby. Profesionálny servis okien s dlhoročnými skúsenosťami. Kontaktujte nás ešte dnes!"}>
            <route.component />
          </CanonicalWrapper>
        } />
        ))}
      </Routes>
    </BrowserRouter>
  );
};



export default AppRouter;
