import ExpandMoreIcon from '@mui/icons-material/ChevronRightRounded';
import { Button, Grow } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';




export default function ServiceCard({imagesrc,
   title, 
   description, 
  openServiceDetails}: 
   
   {imagesrc: string, 
    title: string, 
    description: string[], 
    openServiceDetails: () => void}) {
  const [expanded, setExpanded] = React.useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true, 
  });



  return (
    <div ref={ref}>
      <Grow in={inView} timeout={1000}>
    <Card sx={{ boxShadow:11, backgroundColor:"background.default"}}>
        <CardHeader style={{ whiteSpace: 'pre-line'}}
        title={title}
      />  
      <CardMedia
      loading='lazy'
      onClick={openServiceDetails}
        component="img"
        sx={{cursor: 'pointer', maxHeight: '400px', width: '100%', height: '100%', objectFit: 'contain', backgroundColor:'background.paper'}}
        
        image={imagesrc}
      /> 
      <CardContent>
        <Typography variant="body2" color="text.primary" >
        <ul>
        {description.map((item, index) => (
          <li key={index}>
            <Typography paragraph variant="body1">
              {item}
            </Typography>
          </li>
        ))}
      </ul>
        </Typography>
      </CardContent>
      <CardActions sx={{justifyContent:'right'}}>
        <Button variant='contained' onClick={openServiceDetails} size="large" sx={{ backgroundColor:"background.paper"}}>
          <Typography variant="button" color="secondary.dark" textAlign="left">
          Viac info 
          </Typography>
          <ExpandMoreIcon sx={{color:'secondary.dark', fontSize:25}} />
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent sx={{backgroundColor:'background.paper'}}>
        <Typography variant="body2" color="text.secondary">
      </Typography>
    </CardContent>
      </Collapse>
    </Card>
    </Grow>
    </div>
  );
}
