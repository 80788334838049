import { Box, Paper, Slide, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import theme from "../../theme";
import { useInView } from "react-intersection-observer";




interface ProductDescriptionProps {
  description: string[];
  images: string[];
  folder: string;
  title: string;
}




export const ProductDescription: React.FC<ProductDescriptionProps> = ({ title, description, images, folder }) => {
  const smallItemStyles: React.CSSProperties = {
    cursor: 'pointer',
    objectFit: 'cover',
    display: 'flex',
    width: '100%',
  };
  const { ref, inView } = useInView({
    triggerOnce: true,
});

  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.up('xs'));
  let stackDirection: "column" | "row" = "column";;
  if (isXs) {
    stackDirection = "column" // Smaller screens
  } else if (isSm) {
    stackDirection = "row"; // Medium screens
  }
 
 
  return (
    <Box  ref={ref} sx={{overflow:"hidden"}}>
    <Slide  direction="right" in={inView} timeout={1000}>
    <Paper elevation={5} sx={{ p: { xs: 3, sm: 5 }, m: { xs: 2, sm: 5 } }} ref={ref} >
      <Box mb={2}>
        <Typography variant="h4" sx={{fontSize
        :{ xs: 'h5.fontSize',
        sm: 'h6.fontSize',
        md: 'h5.fontSize',
        lg: 'h4.fontSize',
        xl: 'h4.fontSize'
        }}
        }>
          {title}
        </Typography>
      </Box>
      <Stack direction={stackDirection} spacing={2} mb={2} alignItems="center" sx={{justifyContent:"center"}} >
        <Box sx={{ boxShadow: 15, borderRadius: 2, pt: 3, pl:2, pr:2, backgroundColor: "primary.dark", border: 5, borderColor: "secondary.light",  }}>
        <Gallery>
        <div
        >
           {images.map((path, index) => {
           const thumbnailPath = `${process.env.PUBLIC_URL}/pictures/${folder}/thumbs/${path}`;
           const originalPath = `${process.env.PUBLIC_URL}/pictures/${folder}/${path}`;
           return(
                <Item<HTMLImageElement>
                key={index}
                cropped
                original={originalPath}
                thumbnail={thumbnailPath}
                width="1000"
                height="1000"
                >
                {({ ref, open }) => (
                    <Box sx={{pb:3, display: 'flex', width:{xl:"300px", lg:"200px", md:"260px", sm:"175px", xs:"200px" }, justifyContent:"center"}}>
                    <img
                        style={smallItemStyles}
                        src={thumbnailPath}
                        ref={ref}
                        onClick={open}
                    />
                    
                    </Box>
                )}
                </Item>
           );
              })}
        </div>
      </Gallery>
        </Box>
        <Box>
          {description.map((desc, index) => (
            <Box mb={2} key={index}>
              <Typography variant="body1" color="textSecondary">
                {desc}
              </Typography>
            </Box>
          ))}
        </Box>
      </Stack>
    </Paper>
    </Slide>
    </Box>
  );

};