import { useLocation, useParams } from "react-router-dom";
import { AllBlindsTexts } from "../texts/blindsMoreTexts";
import { Box, Grid, Grow, Stack, styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { MoreLandingModel } from "./models/moreLanding";
import NavBar from "./main/nav-bar";
import { ProductDescription } from "./models/productDescription";
import { ProductList } from "./models/productList";
import WarrantyServices from "./main/warrantyServices";
import ContactForm from "./main/contactForm";
import ScrollTop from "./models/ScrollToTop";
import Footer from "./main/footer";
import { useInView } from "react-intersection-observer";



const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(4),
  textAlign: 'center',
}));


export const BlindsMoreSpecs = () => {
  const { productId } = useParams<{ productId: string }>();
  const products = AllBlindsTexts[productId as string];
  const location = useLocation();
  const targetId = location.state?.targetId;




  useEffect(() => {
    console.log(productId);
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }

  }, [targetId]);

  return (
    <Box sx={{ width: '100%', position: 'realtive', backgroundColor: 'secondary.light' }}>
      <Stack
        display='flex'
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <NavBar />
        {products.map((prd, index) => (

          <React.Fragment key={index}>
            <MoreLandingModel title={prd.title} bgImage={prd.bgImage} />
            <Grid
              container
              width={"100%"}
              display={"flex"}
              maxWidth={"1920px"}
              rowSpacing={1}
              rowGap={{ xs: 1, sm: 2, md: 3 }}
              columns={{ xs: 9, sm: 9, lg: 18, xl: 18 }}
              sx={{
                m: 0, p: 0
              }}>
              <Grid item xs={9}>
                <ProductDescription folder="blinds" title="Opis Produktu" description={prd.description} images={prd.galleryImages} />
              </Grid>
              <Grid item xs={9}>
                <ProductList  items={prd.advantages} />
              </Grid>
              <Grid item xs={18}>
                <WarrantyServices />
              </Grid>
            </Grid>
            <StyledBox
              sx={{
                borderRadius: { xs: 5, sm: 15 },
                boxShadow: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: { xs: 3, sm: 5 },
                m: { xs: 3, sm: 10 }
              }}>
              <Typography
                fontWeight={800}
               
                color="secondary.light"
                sx={{
                  fontSize: {
                    xs: 'h5.fontSize',
                    sm: 'h4.fontSize',
                    md: 'h4.fontSize',
                    lg: 'h3.fontSize',
                  },
                }}
                 variant="h3"
              >Využite možnosť bezplatného zamerania, výber vzoriek priamo u Vás doma</Typography>
              <Typography
                variant="h3"
                fontWeight={800}
                color="secondary.light"
                sx={{
                  pt: { xs: 5, md: 10 },
                  fontSize: {
                    xs: 'h5.fontSize',
                    sm: 'h4.fontSize',
                    md: 'h4.fontSize',
                    lg: 'h3.fontSize',
                  },
                }}
              >Odborne a so zárukou</Typography>
            </StyledBox>
          </React.Fragment>
        ))}
        <ContactForm />
        <Footer />
        <ScrollTop />
      </Stack>
    </Box>
  );
}