import CallIcon from '@mui/icons-material/Call';
import { alpha, Box, Button, Container, CssBaseline, Divider, Grow, List, ListItem, ListItemText, Slide, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import { LandingText } from '../../texts/landingText';

interface LandingPageModelProps {
    texts: LandingText;
    component: React.ComponentType<any>;
}

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    maxWidth: 800,
    textAlign: 'center',
}));

const Landing = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    display: 'flex', // Enable flexbox
    alignItems: 'center',
    flexDirection: 'column'
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: 48,
    fontWeight: 700,
    color: theme.palette.common.white,
    lineHeight: 1.2,
}));
const SubHeaderText = styled('div')(({ theme }) => ({
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
    maxWidth: 400,
    backdropFilter: 'blur(2px)'
}));

export const LandingModel: React.FC<LandingPageModelProps> = ({ texts, component }) => {
    const [dense, setDense] = React.useState(false);
    const [animate, setAnimate] = useState(false);
    const [secAnimate, setSecAnimate] = useState(false);
    const Component = component;

    useEffect(() => {
        setAnimate(true);
    }, []);
    useEffect(() => {
        if (animate) {
            const timeoutId = setTimeout(() => {
                setSecAnimate(true);
            }, 300); // delay 500ms
            return () => clearTimeout(timeoutId);
        }
    }, [animate]);


    return (
        <React.Fragment>
            <CssBaseline />
            <Landing
                color='primary'
                disableGutters
                maxWidth={false}
            >
                <Box sx={{
                    boxShadow: 10,
                    height: 'auto',
                    position: 'relative',
                    padding: .01,
                    width: '100vw',
                    backgroundImage:
                        `url(${process.env.PUBLIC_URL + texts.bgImage
                        })`,
                    backgroundSize: 'cover',
                    backgroundPosition: { xs: 'top 45em right 85%', sm: 'right 35%' }
                }}>
                    <Slide
                        in={animate}
                        timeout={400}>
                        <StyledBox sx={{
                            boxShadow: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: { xs: 3, sm: 5 },
                            ml: { sm: 8 },
                            mt: { sm: 10 },
                            mr: { md: 10, sm: 2 }
                        }}>
                            <StyledTypography
                                variant="h1"
                                sx={{
                                    fontSize: {
                                        xs: 'h5.fontSize',
                                        sm: 'h4.fontSize',
                                        md: 'h4.fontSize',
                                        lg: 'h3.fontSize',
                                    },
                                }}
                            >{texts.title}</StyledTypography>
                            <Divider sx={{ backgroundColor: 'white', mt: 4, mb: 4, height: 7, width: 100 }} />
                            <StyledTypography
                                variant="h2"
                                sx={{
                                    whiteSpace: "pre-wrap",
                                    fontSize: {
                                        xs: 'h5.fontSize',
                                        sm: 'h4.fontSize',
                                        md: 'h4.fontSize',
                                        lg: 'h3.fontSize',
                                    },
                                }}
                            >{texts.subtitle}</StyledTypography>
                        </StyledBox>
                    </Slide>
                    <Grow
                        in={secAnimate}
                        timeout={1000}>
                        <SubHeaderText
                            sx={{
                                mt: 3,
                                mb: { md: 50, xs: 33 },
                                mr: { md: 10, sm: 2 },
                                ml: { md: 50, sm: 30, xs: 3 }
                            }}>
                            <Stack
                                direction="row"
                                divider={<Divider sx={{
                                    backgroundColor: 'black',
                                    opacity: 0.4,
                                    borderRightWidth: 2
                                }} orientation='vertical' flexItem />}
                                spacing={2}>
                                <Box />
                                <List dense={dense} >
                                    <ListItem>
                                        <ListItemText
                                            primary={<Typography
                                                variant="h2"
                                                sx={{
                                                    fontSize: { xs: 'subtitle1.fontSize', md: 'h6.fontSize' },
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {texts.description}
                                            </Typography>} />
                                    </ListItem>
                                    <ListItem>
                                        <Button variant="contained" color='secondary'
                                            startIcon={<CallIcon />}
                                            sx={{
                                                mr: { sm: 0 },
                                                ml: { md: 14, sm: 14, xs: 5 }
                                            }}>
                                            <Link to="contact-form" smooth={true} duration={750}>
                                                {texts.button}
                                            </Link>
                                        </Button>
                                    </ListItem>
                                </List>
                            </Stack>
                        </SubHeaderText>
                    </Grow>
                </Box>
                <Component />
            </Landing>
        </React.Fragment>
    );
}