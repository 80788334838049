import CallIcon from '@mui/icons-material/AddIcCallRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Slide, styled, TextField, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { contactFormText } from '../../texts/contactFormText';
import EmailTemplate from '../email/template';


const FormBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.info.light,
    padding: theme.spacing(4),
    display: 'inline-block',
    boxShadow: theme.shadows[10],
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const ContactForm = () => {
    const containerRef = React.useRef<HTMLElement>(null);
    const [open, setOpen] = React.useState(false);
    const [dialogText, setDialogText] = React.useState({
        content: '',
        title: '',
    });
    const [loading, setLoading] = React.useState(false);

    const initialFormData = {
        name: '',
        email: '',
        phone: '',
        service: ''
    };

    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        service: ''
    });

    const validateForm = () => {
        let formIsValid = true;
        let errors = {
            name: '',
            email: '',
            phone: '',
            service: ''
        };

        if (!formData.name) {
            formIsValid = false;
            errors.name = 'Uveďte Vaše meno';
        }

        if (!formData.email) {
            formIsValid = false;
            errors.email = 'Uveďte Váš email';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formIsValid = false;
            errors.email = 'Zadajte platný email';
        }

        if (!formData.phone) {
            formIsValid = false;
            errors.phone = 'Uveďte Vaše telefónne číslo';
        } else if (!/^(\+421|0)9\d{8}$/.test(formData.phone)) {
            formIsValid = false;
            errors.phone = 'Zadajte platné telefónne číslo';
        }

        if (!formData.service) {
            formIsValid = false;
            errors.service = 'Uveďte Vašu správu';
        }

        setErrors(errors);
        return formIsValid;
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        service: '',
    });

    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleClose = () => {
        setOpen(false);
    };


    const sendEmail = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        const emailHtml = EmailTemplate({
            customerName: formData.name,
            customerEmail: formData.email,
            phoneNumber: formData.phone,
            serviceRequested: formData.service,
            websiteUrl: 'https://alvip-okno.sk',
            sendDate: new Date().toLocaleString(),
        });
        setLoading(true);
        setFormData(initialFormData);
        const name = formData.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        try {
            const response = await fetch('https://email-service-kappa-nine.vercel.app/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: 'info@alvipstav.sk',
                    sender: `${name.replaceAll(" ", "")}@alvip-okno.sk`,
                    subject: `Nová požiadavka od ${formData.name}`,
                    html_body: emailHtml,
                    replyTo: formData.email,
                }),
            });

            if (response.ok) {
                setDialogText({ content: 'Váš Email bol úspešne odoslaný.', title: 'Ďakujeme, že ste nás kontaktovali' });
                console.log('Email sent successfully');
                setFormData(initialFormData);
            } else {
                setDialogText({ content: 'Pri odosielaní emailu nastala chyba, skúste to znova neskôr.', title: 'Chyba pri odosielaní emailu' });
                console.error('Failed to send email');
            }
            setLoading(false);
            setOpen(true);
            
        } catch (error) {
            console.error('Error:', error);
            setDialogText({ content: 'Pri odosielaní emailu nastala chyba, skúste to znova neskôr.', title: 'Chyba pri odosielaní emailu' });
            setOpen(true);
            setLoading(false);
        }
    };




    return (
        <React.Fragment>
            <FormBox
                id="contact-form"
                sx={{
                    height: 'auto',
                    padding: .01,
                    width: '100vw',
                    boxShadow: 10,
                    display: 'flex', // Enable flexbox
                    alignItems: 'center',
                    flexDirection: 'column', // Enable flexbox

                }}>
                <Grid

                    ref={ref}
                    container rowSpacing={1}
                    maxWidth={"1920px"}
                    rowGap={{ xs: 1, sm: 2, md: 3 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    columns={19}>
                    <Slide direction="right" in={inView} timeout={1000}>
                        <Grid item
                            alignContent={'center'}
                            textAlign={'center'}
                            xs={19}
                            md={9}
                            pl={10}
                        >
                            <Typography sx={{
                                fontSize: {
                                    xs: 'h5.fontSize',
                                    sm: 'h4.fontSize',
                                    md: 'h4.fontSize',
                                    lg: 'h3.fontSize',
                                }
                            }} mb={5} mt={5} ml={5} mr={{ md: 0, xs: 5 }}>
                                {contactFormText.phoneTitle}
                            </Typography>
                            <Button
                                component="a"
                                onClick={() =>{if (window.innerWidth < 600)  window.gtag_report_conversion('tel:+421911671234')}}

                                sx={{ ml: { md: 5, xs: 0 }, height: { xs: 55, sm: 60, md: 75, lg: 85, xl: 110 }, fontSize: { xs: 23, sm: 30, md: 28, lg: 38, xl: 40, } }}
                                variant="contained"
                                size='large'
                                startIcon={
                                    <IconButton disabled >
                                        <CallIcon sx={{ fontSize: { xs: 30, sm: 33, md: 36, lg: 41, xl: 55 }, color: 'secondary.light' }} />
                                    </IconButton>}>
                                {contactFormText.phone}
                            </Button>
                        </Grid>
                    </Slide>
                    <Grid item
                        sx={{
                            display: 'flex',
                            mt: 5,
                            mb: 5,
                            justifyContent: 'center',
                        }}
                        xs={0}
                        md={1}>
                        <Divider sx={{ backgroundColor: 'black' }} orientation='vertical' />
                    </Grid>
                    <Grid
                        sx={{ overflow: 'hidden' }}
                        item
                        xs={19}
                        md={9}>
                        <Slide direction="left" in={inView} container={containerRef.current} timeout={1000}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: 'h5.fontSize',
                                            sm: 'h4.fontSize',
                                            md: 'h4.fontSize',
                                            lg: 'h3.fontSize',
                                        }
                                    }}
                                    textAlign={'center'}
                                    variant="h4"
                                    mb={5}
                                    mt={5}
                                    mr={5}
                                    pl={{ xs: 5 }}>
                                    {contactFormText.title} </Typography>
                                <form onSubmit={sendEmail}>
                                    <Grid
                                        container spacing={2}
                                        pl={{ xs: 2, sm: 7 }}
                                        pr={{ xs: 2, sm: 7 }}>
                                        <Grid item xs={12}>
                                            <TextField value={formData.name} error={errors.name != ''} helperText={errors.name} label={contactFormText.name} fullWidth name="name" onChange={handleInputChange} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField value={formData.email} error={errors.email != ''} helperText={errors.email} label={contactFormText.email} fullWidth name="email" onChange={handleInputChange} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField value={formData.phone} error={errors.phone != ''} helperText={errors.phone} label={contactFormText.customerPhone} fullWidth name="phone" onChange={handleInputChange} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField value={formData.service} error={errors.service != ''} helperText={errors.service} label={contactFormText.message} fullWidth multiline rows={4} name="service" onChange={handleInputChange} />
                                        </Grid>
                                        <Grid
                                            item xs={12}
                                            pb={10}
                                            display={'flex'}
                                            justifyContent={'center'}
                                        >
                                            <LoadingButton loading={loading} type="submit" variant="contained" color="primary" sx={{ pl: { xs: 5, md: 10 }, pr: { xs: 5, md: 10 } }}  >
                                                {contactFormText.button}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </form>
                                <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle>{dialogText.title}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            {dialogText.content}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>Zavrieť</Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </Slide>
                    </Grid>
                </Grid>
            </FormBox>
        </React.Fragment>
    );
};

export default ContactForm;
