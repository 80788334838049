import ExpandMoreIcon from '@mui/icons-material/ChevronRightRounded';
import { useInView } from "react-intersection-observer";
import { ServiceCard } from "../../texts/servicesText";

import { Button, Card, CardActions, CardContent, CardMedia, Grow, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { baseurl } from "../../router";

interface ButtonServiceCardProps {
  texts: ServiceCard;
}

export const ButtonServiceCard: React.FC<ButtonServiceCardProps> = ({ texts }) => {

  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const navigate = useNavigate();

  const scrollToComponent = (id: string, link: string) => () => {
    navigate(baseurl + link, { state: { targetId: id } });
    // scroller.scrollTo(id, {
    //   duration: 750,
    //   smooth: true,
    // });
  };


  return (
    <div ref={ref}>
      <Grow in={inView} timeout={1000}>
        <Card sx={{ boxShadow: 11, backgroundColor: "background.default" }}>
          {texts.titles.map((title) => (
            <CardActions sx={{ justifyContent: 'left', p: 0 }}>
              <Button disabled={title.link === "/"} 
              sx={{ width: '100%', justifyContent: "left", 
                mb: 1, 
                boxShadow: 3, 
                '&.Mui-disabled': {
                        backgroundColor: "background.default", // Ensure the disabled color is applied
                        color: "white", // Ensure the disabled text color is applied
                      },
                backgroundColor: "background.default" }} variant="contained" color="primary" onClick={scrollToComponent(title.targetId, title.link)} size="large" >
                <Typography variant="subtitle1" sx={{ fontWeight: 800 }} color="black" textAlign="left">
                  {title.content + (title.link === "/" ? "" : " >")}
                </Typography>
              </Button>
            </CardActions>
          ))}
          <CardMedia
            component="img"
            height="250vh"
            image={process.env.PUBLIC_URL + texts.imagesrc}
            alt={texts.titles.map((title) => title.content).join(", ")}
            loading="lazy"
          />
          <CardContent>
            <Typography variant="body2" color="text.primary" >
              <ul>
                {texts.points.map((item, index) => (
                  <li key={index}>
                    <Button disabled={item.link === "/"} variant="contained" sx={{
                      mt: 1, boxShadow: 3,
                      '&.Mui-disabled': {
                        backgroundColor: "background.default", // Ensure the disabled color is applied
                      },
                      backgroundColor: "background.default",
                      width: '100%',
                      justifyContent: "left"
                    }} onClick={scrollToComponent(item.targetId, item.link)} size="large" >
                      <Typography variant="body2" color="black" textAlign="left">
                        {item.content + (item.link === "/" ? "" : " >")}
                      </Typography>
                    </Button>
                  </li>
                ))}
              </ul>
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'right' }}>
            <Link to="contact-form" smooth={true} duration={750} >
              <Button variant="contained" color="primary" size="large" sx={{ backgroundColor: "background.paper" }}>
                <Typography variant="button" color="secondary.dark" textAlign="left">
                  Dohodnite si bezplatnú obhliadku
                </Typography>
                <ExpandMoreIcon sx={{ color: 'secondary.dark', fontSize: 25 }} />
              </Button>
            </Link>
          </CardActions>
        </Card>
      </Grow>
    </div>
  );
}

function setOpen(arg0: boolean) {
  throw new Error("Function not implemented.");
}
