import CallIcon from '@mui/icons-material/Call';
import { alpha, Box, Button, Container, CssBaseline, Divider, Grow, IconButton, Slide, Typography, useTheme } from '@mui/material';
import { sizeWidth, Stack, styled } from '@mui/system';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-scroll';

interface MoreLandingPageModelProps {
    bgImage: string;
    title: string;
}

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    maxWidth: 800,
    textAlign: 'center',
}));

const Landing = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    display: 'flex', // Enable flexbox
    alignItems: 'center',
    flexDirection: 'column'
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: 48,
    fontWeight: 700,
    color: theme.palette.common.white,
    lineHeight: 1.2,
}));


export const MoreLandingModel: React.FC<MoreLandingPageModelProps> = ({ bgImage, title }) => {
    const containerRef = React.useRef<HTMLElement>(null);
    // const [animate, setAnimate] = useState(false);

    const { ref, inView } = useInView({
        triggerOnce: true,
    });

    


    return (
        <React.Fragment>
            <CssBaseline />
            <Landing
               ref={ref}
                color='primary'
                disableGutters
                sx={{overflow: 'hidden'}}
                maxWidth={false}
            >
                <Box 
                     ref={containerRef}
                sx={{
                    boxShadow: 10,
                    minHeight: '65vh',
                    height: 'auto',
                    padding: .01,
                    width: '100vw',
                    backgroundImage:
                        `url(${process.env.PUBLIC_URL + bgImage
                        })`,
                    backgroundSize: 'cover',
                    //backgroundPosition: { xs: 'top 45em right 85%', sm: 'right 35%' }
                }}>
                    <Slide
                        container={containerRef.current}
                        in={inView}
                        direction='left'
                        timeout={500}>
                        <StyledBox sx={{
                            boxShadow: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: { xs: 3, sm: 5 },
                            ml: { sm: 8 },
                            mt: { sm: 10 },
                            mr: { md: 10, sm: 2 }
                        }}>
                            <StyledTypography
                                variant="h3"
                                sx={{
                                    fontSize: {
                                        xs: 'h5.fontSize',
                                        sm: 'h4.fontSize',
                                        md: 'h4.fontSize',
                                        lg: 'h3.fontSize',
                                    },
                                }}
                            >{title}</StyledTypography>
                            <Divider sx={{ backgroundColor: 'white', mt: 4, height: 7, width: 100 }} />
                            
                        </StyledBox>
                    </Slide>
                    <Grow
                        in={inView}
                        timeout={1000}>
                        <Button variant="contained" color='secondary'
                            sx={{
                                marginTop: 5,
                                fontSize: {
                                    xs: 'h6.fontSize',
                                    sm: 'h5.fontSize',
                                    md: 'h5.fontSize',
                                    lg: 'h4.fontSize',
                                },
                                mr: { xs: 1},
                                ml: { md: 14, sm: 14, xs: 1 }
                            }}
                            >
                                <Link to="contact-form" smooth={true} duration={750}>
                                <Stack direction="row" alignItems="center">
                                 <CallIcon  sx={{ 
                                    mr: 1,
                                    fontSize: {
                                        xs: 'h6.fontSize',
                                        sm: 'h5.fontSize',
                                        md: 'h5.fontSize',
                                        lg: 'h4.fontSize',
                                    }, color: 'primary.dark' }} />
                            
                            <Typography variant="h5" sx={{fontSize
                                :{ 
                                xs: 'h6.fontSize',
                                sm: 'h5.fontSize',
                                md: 'h5.fontSize',
                                lg: 'h4.fontSize',
                                xl: 'h4.fontSize'
                            }
                            }}>
                            Inštalácia odborne a so zárukou
                            </Typography>
                            </Stack>
                            </Link>
                        </Button>
                    </Grow>
                </Box>
            </Landing>
        </React.Fragment>
    );
}